<template>
  <div>
    <b-container class="bg-light sandbox-footer-menu">
      <b-row class="main-content-wrapper" no-gutters>
        <b-col>
          <hr />
          <h3>Sandbox Footer Menu</h3>
          <ul>
            <li><router-link to="/sandbox/simple-view">SimpleView</router-link></li>
            <li><router-link to="/sandbox/chart-js">ChartJs</router-link></li>
            <li><router-link to="/sandbox/environment-info">Environment info</router-link></li>
            <li><router-link to="/sandbox/report-template">Report Template</router-link></li>
            <li><router-link to="/sandbox/vueselect">Vueselect</router-link></li>
            <li><router-link to="/sandbox/notification">Notification Component</router-link></li>
            <li><router-link to="/sandbox/graphdropdown">Graph DropDown</router-link></li>
            <li><router-link to="/sandbox/mobile">Mobile DropDown</router-link></li>
            <li><router-link to="/sandbox/combination-chart-bar-and-line">giorgi - Combination Chart - Bar + Line</router-link></li>
            <li><router-link to="/sandbox/difference-chart">giorgi - Spirecta Difference Chart</router-link></li>
            <li><router-link to="/sandbox/bar-chart">giorgi - Bar Chart</router-link></li>
            <li><router-link to="/sandbox/cashflow-chart">giorgi - Cashflow Chart</router-link></li>
            <li><router-link to="/sandbox/cashbook-chart">giorgi - Cashbook Chart</router-link></li>
            <li><router-link to="/sandbox/income-expense-12-months">giorgi - Income Expense 12 Months</router-link></li>
            <li><router-link to="/sandbox/sunburst-2levels">giorgi - Sunburst Chart 2 levels</router-link></li>
            <li><router-link to="/sandbox/sunburst-treemap">giorgi - Sunburst Chart / Treemap</router-link></li>
            <li><router-link to="/sandbox/nested-treemap">giorgi - Nested / Treemap</router-link></li>
            <li><router-link to="/sandbox/accounts/create/assets">Accounts - Assets</router-link></li>
            <li><router-link to="/sandbox/accounts/create/liabilities">Accounts - Liabilities</router-link></li>
            <li><router-link to="/sandbox/performance">Report - Performance</router-link></li>
            <li><router-link to="/sandbox/balance/subtypes">Report - Balance</router-link></li>
            <li><router-link to="/sandbox/period-selector">Period selector</router-link></li>
            <li><router-link to="/sandbox/report-filter">Report filter</router-link></li>
            <li><router-link to="/sandbox/create-tink-connection">Create Tink Connection</router-link></li>
            <li><router-link to="/sandbox/create-account-group-modal">Create Account Group Modal</router-link></li>
            <li><router-link to="/sandbox/autocomplete-input">Autocomplete Input</router-link></li>
            <li><router-link to="/sandbox/column-toggler">Column Toggler</router-link></li>
            <li><router-link to="/sandbox/currency-input">Currency Input</router-link></li>
            <li><router-link to="/sandbox/custom-number-input">Custom Number Input</router-link></li>
          </ul>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'SandboxFooterMenu'
}
</script>

<style scoped>
.sandbox-footer-menu
{
  padding: 0 35px 0 35px;
}
.main-content-wrapper
{
  padding-top:25px;
}
</style>
