<template>
  <div class="sandbox">
    <router-view></router-view>
    <footer-menu></footer-menu>
  </div>
</template>

<script>
import FooterMenu from './inc/SandboxFooterMenu'
export default {
  name: 'Sandbox',
  components: { FooterMenu }
}
</script>

<style scoped>
</style>
